import PropTypes from 'prop-types';
import ErrorMessage from '../ErrorMessage';
import { getIn, useFormikContext } from 'formik';
import FormGroupTemplate from './FormGroupTemplate';
import { createLabelId, createErrorMsgId } from '../helpers';
import FieldLabel from '../FieldLabel';

const FormGroup = ({
  fieldName,
  fieldTitle,
  fieldComponent: FieldComponent,
  descriptionComponent: DescriptionComponent,
  description,
  className,
  hideError,
  removeError,
  removeLabel,
  ...fieldProps
}) => {
  const formik = useFormikContext();

  let fieldError = getIn(formik.errors, fieldName);
  if (fieldError && getIn(formik.touched, fieldName))
    className = className ? `${className} row-error` : 'row-error';
  else
    fieldError = null;

  const formName = formik.status.formName;
  const labelId = createLabelId(formName, fieldName);
  const errorId = createErrorMsgId(formName, fieldName);
  const errorClassName = hideError ? 'visually-hidden' : '';

  if (FieldComponent.notNative)
    fieldProps.labelId = labelId;

  return (
    <FormGroupTemplate
      className={className}
      label={<FieldLabel fieldName={fieldName} required={fieldProps.required} notNative={FieldComponent.notNative}>{fieldTitle}</FieldLabel>}
      field={<FieldComponent fieldName={fieldName} fieldTitle={fieldTitle} {...fieldProps} />}
      error={<ErrorMessage fieldName={fieldName} id={errorId} className={errorClassName}>{fieldError}</ErrorMessage>}
      description={DescriptionComponent ? <DescriptionComponent>{description}</DescriptionComponent> : description}
      aria-labelledby={labelId}
      removeError={removeError}
      removeLabel={removeLabel}
    />
  );
};

FormGroup.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fieldTitle: PropTypes.string,
  fieldComponent: PropTypes.elementType.isRequired,
  descriptionComponent: PropTypes.elementType,
  description: PropTypes.string,
  className: PropTypes.string,
  hideError: PropTypes.bool,
  removeError: PropTypes.bool,
  removeLabel: PropTypes.bool,
};

export default FormGroup;
